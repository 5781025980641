<div class="card">
  <p>
    Cette version de l'application n'est <u>plus supportée</u>.<br />
    Veuillez installer l'application mobile YuClub sur votre appareil smartphone ou tablette
  </p>
  <div class="row">
    @if (osDevice === OS.iOS || osDevice === OS.Mac || osDevice === OS.Windows) {
      <a href="{{ appleStoreAppUrl }}">
        <img
          [ngSrc]="imageUrl('apple-app-store')"
          height="50"
          width="150"
          alt="Télécharger sur l'Apple Store"
        />
      </a>
    }
    @if (osDevice === OS.Android || osDevice === OS.Windows) {
      <a href="{{ googlePlayAppUrl }}">
        <img
          [ngSrc]="imageUrl('google-play')"
          height="50"
          width="169"
          alt="Télécharger sur le Google Play Store"
        />
      </a>
    }
  </div>
  <p>
    Vous pouvez également scanner le QR code ci-dessous pour télécharger l'application directement sur votre appareil.
  </p>

  <img
    [ngSrc]="imageUrl('get-app-qrcode')"
    height="200"
    width="200"
    alt="Télécharger sur les stores mobile en scannant ce QR code"
    class="qrcode"
  />
  <p>Merci d'avoir choisi YuClub pour fidéliser vos clients !</p>
</div>
