import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../environments/environment';
import { OS, OsType } from '../../types/os.type';

@Component({
  selector: 'yc-mobile-app',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './mobile-app.component.html',
  styleUrl: './mobile-app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAppComponent {
  osDevice: OsType = inject(DeviceDetectorService).os as OsType;
  OS: typeof OS = OS;

  appleStoreAppUrl = environment.appleStoreAppUrl;
  googlePlayAppUrl = environment.googlePlayAppUrl;

  imageUrl(filename: string): string {
    return `${environment.apiUrl}/public/img/${filename}.svg`;
  }
}
