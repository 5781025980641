import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MobileAppComponent } from './components/mobile-app/mobile-app.component';

@Component({
  selector: 'yc-root',
  standalone: true,
  imports: [MobileAppComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {}
