import { IMAGE_LOADER, ImageLoaderConfig, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';

registerLocaleData(localeFr);

export const appConfig: ApplicationConfig = {
  providers: [
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideAnimations(),
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig): string => {
        try {
          // S'il s'agit d'une URL absolue, on la retourne telle quelle
          new URL(config.src);
          return config.src;
        } catch (error) {
          // On retourne l'URL relative au bundle
          return `/assets/img/${config.src}`;
        }
      },
    },
  ],
};
