const baseUrl: string = 'pwa.staging.yuclub.fr';

export const environment = {
  testToken: 'd0f04eaa-2830-4282-92bf-18f11ff2a1cb',
  apiUrl: `https://${baseUrl}/api`,
  imagesCdnUrl: `https://${baseUrl}`,
  // URLs des applications sur les stores
  appleStoreAppUrl: 'https://apps.apple.com/fr/app/yuclub/id6496203195',
  googlePlayAppUrl: 'https://apps.apple.com/fr/app/yuclub/id6496203195',
};
